<template >
    <div>
        <Find/>
    </div>
</template>
<script>
export default {
    components: {
        Find: () => import('@/components/adverts/Find.vue')
    }
}
</script>
